import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

import type { Theme } from '@ui/ThemeRegistry/theme.types';

// import { IFrameVariants } from './IFrame.types';

const defaultProps: ComponentsProps['IFrame'] = {};

const styleOverrides: ComponentsOverrides<Theme>['IFrame'] = {
  root: ({ theme, ownerState }) => ({
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '250px',
    maxHeight: '80vh',

    ...(ownerState?.height ? { height: ownerState.height } : { height: '100%' }),

    iframe: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      border: 0
    }
  })
};

const createVariants = (theme: Theme): ComponentsVariants['IFrame'] => [];

export const iframeTheme = (theme: Theme): ThemeOptions => ({
  components: {
    IFrame: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default iframeTheme;
