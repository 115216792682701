import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

import { AccordionVariants } from './Accordion.types';
import { type Theme } from '../ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['Accordion'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Accordion'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyBackgroundColor({ ownerState, theme }),
    containerType: 'inline-size',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }),

  introText: { gridColumn: 'start / end' },

  contentOuterGrid: ({ theme, ownerState }) => {
    let layout: any = {
      '> *': {
        gridColumnStart: 'start',
        gridColumnEnd: 'end'
      }
    };
    if (
      ownerState.variant === AccordionVariants.TwoColumnsOddEven ||
      ownerState.variant === AccordionVariants.TwoColumnsTopToBottom
    ) {
      layout = {
        '> div:first-of-type': {
          gridColumnStart: 'start',
          gridColumnEnd: 8,
          marginRight: theme.spacing(1)
        },
        '> div:nth-of-type(2)': {
          gridColumnStart: 8,
          gridColumnEnd: 'end',
          marginLeft: theme.spacing(1)
        }
      };
    }
    return {
      ...layout,
      gridGap: 0
    };
  },

  summary: ({ theme, ownerState }) => ({
    ...theme.typography.h4
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['Accordion'] => [];

export const AccordionTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Accordion: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default AccordionTheme;
