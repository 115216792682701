import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import type { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['PageDocument'] = {};

const styleOverrides: ComponentsOverrides<Theme>['PageDocument'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyBackgroundColor({
      ownerState: { ...ownerState, backgroundColor: 'white' },
      theme
    }),
    'containerType': 'inline-size',
    'position': 'relative',
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',

    '& > *': {
      paddingTop: 'var(--section-padding)',
      paddingBottom: 'var(--section-padding)'
    }
  }),

  featuredMediaWrap: ({ theme, ownerState }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 'var(--grid-gap-double)'
  }),

  sideContentWrap: ({ theme }) => ({
    gridColumnStart: 'start',
    gridColumnEnd: 'end',
    paddingBottom: 'var(--grid-gap-double) 0',
    gridRow: 2,

    [theme.breakpoints.up('md')]: {
      gridColumnStart: 'two-start',
      gridColumnEnd: 'eleven-end'
    }
  }),

  sideContentInnerWrap: {
    'display': 'flex',
    'flexDirection': 'column',
    'borderLeft': 'solid',
    'borderLeftWidth': '1px',
    'paddingLeft': 'var(--grid-gap)',

    '& > *': {
      marginBottom: 0
    }
  },

  detailsLabel: {
    '&': {
      paddingBottom: 'var(--grid-gap)'
    }
  },

  bodyHeader: {
    '&:not(:first-of-type)': {
      paddingTop: 'var(--grid-gap)',
      marginTop: 'var(--grid-gap)',
      borderTop: 'solid',
      borderTopWidth: '1px'
    }
  },

  contentWrap: ({ theme }) => ({
    padding: 'var(--grid-gap-double) 0',
    gridColumnStart: 'start',
    gridColumnEnd: 'end',
    gridRow: 1,

    [theme.breakpoints.up('md')]: {
      gridColumnStart: 'two-start',
      gridColumnEnd: 'eleven-end'
    }
  }),

  docContentWrap: ({ theme }) => ({
    gridColumnStart: 'start',
    gridColumnEnd: 'end',
    gridRow: 2
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['PageDocument'] => [];

export const pageDocumentTheme = (theme: Theme): ThemeOptions => ({
  components: {
    PageDocument: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default pageDocumentTheme;
